/**
 * Theme for BBC UK site
 */
import type { Theme } from '@emotion/react';
import { buildTheme, globals } from 'common-ui';
import merge from 'lodash/merge';
import { icons } from 'common-font-icons';
import type { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types.d';
import { buildThemeMixinsLW, buildThemeWidgetStylesLW, buildThemeVarsLW } from 'dw-uk-law/theme';
import { crimsonTextFont } from '../common/nextFont';

export const buildThemeVarsBBC = (vars: Pick<Theme, CommonThemeVars>) => {
  //primary
  const bbcPink = '#be2a77'; //color1
  const bbcPurple = '#531c7b'; //color2
  const bbcCream = '#F6F5F2'; //color3
  //secondary
  const bbcLightPurple = '#810086'; //color4
  const bbcLightGreen = '#027B1E'; //color5
  const bbcBlue = '#2D469B'; //color6
  const bbcLightPink = '#A8508F'; //color7
  const bbcDarkGreen = '#004E09'; //color8
  const bbcSand = '#AC5F25'; //color9
  const bbcSvgCheckboxBg = '%23760640';

  //Category Banners
  const whiteWine = '#E6EFF7';
  const redWine = '#7A0040';
  const roseWine = '#FBE6F1';
  const champagneAndSparkling = '#674F3E';
  const dessert = '#55002D';
  const fortifiedWine = '#D5BFB1';
  const wineBox = '#C896B1';
  const color10 = '#760640'; //color10  //TODO: confirm is this is redundant
  //CTA colors
  const bbcCTAPink = bbcPink;
  const bbcCTARolloverPink = '#6F1D3E'; //color10
  const bbcCTAPurple = bbcPurple;
  const bbcBodyColor = '#222222';

  const crimsonText = crimsonTextFont.style.fontFamily;
  const georgia = 'Georgia, serif';

  const lwThemeVars = buildThemeVarsLW(vars);

  return merge({}, lwThemeVars, {
    meta: {
      name: 'BBC UK',
    },
    icons,
    assets: {
      headerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo.svg`,
      headerLogoMobileImgUrl: `${globals.assetsPath}/next/images/brand-logo-mobile.svg`,
      footerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo-dark.svg`,
    },
    colors: {
      primary1: bbcPink,
      primary2: bbcPurple,
      primary3: bbcCream,
      secondary1: bbcLightPurple,
      secondary2: bbcLightGreen,
      secondary3: bbcBlue,
      secondary4: bbcLightPink,
      secondary5: bbcDarkGreen,
      secondary6: bbcSand,
      secondary7: color10,
      secondary8: bbcBodyColor,

      //specific colour definitions
      btnsPrimaryBg: bbcCTAPink,
      btnsPrimaryColor: vars.colors.white,
      btnsPrimaryHoverBg: bbcCTARolloverPink,
      btnsPrimaryHoverColor: vars.colors.white,
      btnsSecondaryBg: vars.colors.white,
      btnsSecondaryColor: vars.colors.black,
      btnsSecondaryHoverBg: bbcCTARolloverPink,
      btnsSecondaryHoverColor: vars.colors.white,
      btnsTertiaryBg: vars.colors.black,
      btnsTertiaryColor: vars.colors.white,
      btnsTertiaryBorder: vars.colors.white,
      btnsTertiaryHoverBg: bbcCTAPink,
      btnsTertiaryHoverColor: vars.colors.white,
      svgCheckboxBg: bbcSvgCheckboxBg,
      savingsTextColor: bbcPink,
      textPrimaryBg: bbcPink,

      linkPrimaryColor: vars.colors.black,
      linkSecondaryColor: bbcCTAPink,
      linkTertiaryColor: bbcCTAPurple,

      headerCartBtn: bbcCTAPink,
      headerCartBtnSmall: bbcCTAPurple,
      headerSignInBtnSmall: bbcCTAPink,

      footerPrimaryBg: vars.colors.grey40,
      footerGuidelinesFillColor: bbcPurple,
      footerIconFillColor: vars.colors.black,
      footerdrinkaware: vars.colors.white,

      bgColors: {
        BBCPink: bbcPink,
        BBCPurple: bbcPurple,
        BBCCream: bbcCream,
        BBCLightPurple: bbcLightPurple,
        BBCLightGreen: bbcLightGreen,
        BBCBlue: bbcBlue,
        BBCLightPink: bbcLightPink,
        BBCDarkGreen: bbcDarkGreen,
        BBCSand: bbcSand,
        whiteWine,
        redWine,
        roseWine,
        champagneAndSparkling,
        dessert,
        fortifiedWine,
        wineBox,
      },
    },
    fonts: {
      sizeH2: '30px',
      sizeH3: '19px',
      familyPrimary: lwThemeVars.fonts.oswald,
      familySecondary: lwThemeVars.fonts.roboto,
      familyTertiary: crimsonText,
      familyHeader: georgia,
      headerMainNavTitle: lwThemeVars.fonts.roboto, //Need to change Georgia
      headerMainNavSubTitle: lwThemeVars.fonts.roboto,
      crimsonText,
      weightLight: '300',
      weightNormal: '400',
      weightMedium: '500',
      weightMediumPlus: '600',
      weightBold: '700',
    },
  });
};

export const buildThemeMixinsBBC = (vars: Omit<Theme, 'mixins' | 'widgets'>) => {
  const themeStyles = buildThemeMixinsLW(vars);

  return merge({}, themeStyles, {
    hxl: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize40};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight100};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize64};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight100};
      }
    `,
    h1: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize36};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight100};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize48};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight100};
      }
    `,
    h2: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize32};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight105};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize40};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight105};
      }
    `,
    h3: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize28};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight105};
      letter-spacing: ${vars.fonts.fontLetterSpacing4};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize34};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight105};
      }
    `,
    h4: `
      font-family: ${vars.fonts.familyHeader};
      font-size: ${vars.fonts.fontSize24};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight110};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize34};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight110};
      }
    `,
    h5: `
      font-family: ${vars.fonts.familyHeader};
      font-size: ${vars.fonts.fontSize22};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight110};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize28};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight110};
      }
    `,
    h6: `
      font-family: ${vars.fonts.familyHeader};
      font-size: ${vars.fonts.fontSize20};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight115};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize26};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight115};
      }
    `,
    h7: `
      font-family: ${vars.fonts.familyHeader};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize18};
        font-weight: ${vars.fonts.weightSemiBold};
        letter-spacing: ${vars.fonts.fontLetterSpacing5};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
    h8: `
      font-family: ${vars.fonts.familyHeader};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize18};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
    h9: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize18};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      text-transform: ${vars.fonts.caseUpper};
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
    eyebrowRegular: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing7};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize14};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    eyebrowSmall: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize10};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing7};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize12};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    utility: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize14};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing9};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize12};
        line-height: ${vars.fonts.fontLineHeight100};
        letter-spacing: ${vars.fonts.fontLetterSpacing8};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    quote: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize24};
      font-weight: ${vars.fonts.weightSemiBold};
      font-style: ${vars.fonts.styleItalic};
      line-height: ${vars.fonts.fontLineHeight110};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize28};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight110};
      }
    `,
    body1: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    body2: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize14};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight130};
      }
    `,
    bodySmall: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight130};
      }
    `,
    caption: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
  });
};

export const buildThemeWidgetStylesBBC = (vars: Omit<Theme, 'widgets'>) => {
  const themeStyles = buildThemeWidgetStylesLW(vars);

  return merge({}, themeStyles, {
    Footer: {
      default: `
      ${themeStyles.Footer.default}
      .footer-bottom-section {
        background: ${vars.colors.footerPrimaryBg};
        box-shadow: 0 0 0 100vmax ${vars.colors.footerPrimaryBg};
        clip-path: inset(0 -100vmax);
        color: ${vars.colors.white}; 
      }
    `,
      navItems: `
      ${themeStyles.Footer.navItems}
    `,
    },
    Search: {
      wrapper: `
      ${themeStyles.Search.wrapper}
      .filter-header {
        h2 {
        font-weight: ${vars.fonts.weightNormal};
        }
        p {
          font-weight: ${vars.fonts.weightBold};
        }
      }
      .grid-view.bbc .ais-Hits-list .ais-Hits-item .addToCart-container .grid-addto-cart-container .grid-addto-cart-btn {
        background-color:  ${vars.colors.primary1};
        &:hover, &:focus {
          background-color: ${vars.colors.btnsPrimaryHoverBg};
          color: ${vars.colors.btnsPrimaryColor};
        }
      }
      `,
    },
    GlobalHeader: {
      mainNavBarWrapper: `
        ${themeStyles.GlobalHeader.mainNavBarWrapper}
        .megaNavTab {
          font-size: 18px;
          text-underline-offset: ${vars.space.lg};
          padding: ${vars.space.lg} 15px;
          display: inline-block;
          font-family: ${vars.fonts.familyHeader};
          color: ${vars.colors.grey50};
          font-weight: 400;
          letter-spacing: ${vars.fonts.fontLetterSpacing3};
          ${vars.breakpoints.xl} {
            padding: ${vars.space.lg} 20px;
          }

          &:hover {
            text-decoation: none;
          }

          &.emphasize, &.emphasize span {
            color: ${vars.colors.primary1};
            font-weight: ${vars.fonts.weightBold};
          }
          &:focus{
            text-decoration: none;
          }

        }
      `,
    },
    Cart: {
      MiniCart: `
      ${themeStyles.Cart.MiniCart}
      .mini-cart-header {
        h3 {
          text-transform: ${vars.fonts.caseUpper};
        }
      }

      .product-link {
        line-height: 20px;
        font-size: 23px;
        letter-spacing: -0.02em;
        font-family: ${vars.fonts.familySecondary};
        ${vars.breakpoints.sm}{ 
          font-weight: ${vars.fonts.weightBold};
          line-height: 24.2px;
          font-size: 22px;
        }
        ${vars.breakpoints.md}{ 
          font-weight: ${vars.fonts.weightBold};
          font-family: ${vars.fonts.familyHeader};
          line-height: 29.9px;
          font-size: 26px;
        }
      }

      .product-link, .price-section .price  {
        color: ${vars.colors.secondary8};
        font-weight: ${vars.fonts.weightMediumPlus};
      }

      .price-section {
        ${vars.breakpoints.md}{ 
          flex-flow: column;
          align-items: baseline;
        }
      }

      .price-section .strike+.sale-price {
        color: ${vars.colors.primary1}
      }

      .gift-other-device h4, .gift-tablet h4 {
        color: ${vars.colors.secondary8};
      } 

      .auc-Recommend .mini-cart-title, .product-item-container .product-title,  .product-price span.price-cart {
        font-size:20px;
        line-height:24px;
        font-weight: ${vars.fonts.weightBold};
        font-family: ${vars.fonts.familySecondary};
        margin:18px 0;
        letter-spacing: -0.02em;
        ${vars.breakpoints.md} {
          font-family: ${vars.fonts.familyHeader};
          font-size:24px;
          line-height:28.8px;
        }
      }

      .auc-Recommend .mini-cart-title, .product-item-container .product-title {
       color: ${vars.colors.secondary8};
      }

      .product-item-container .product-title {
        height:auto;
      }

      .continue-shopping-text {
        color: ${vars.colors.secondary8};
        font-family: ${vars.fonts.familySecondary};
        font-size: 12px;
        font-weight: ${vars.fonts.weightMediumPlus};
        line-height: 14px;
        letter-spacing: 0.02em;
      }
      `,
      Pill: `
        ${themeStyles.Cart.Pill}
        background-color: ${vars.colors.btnsPrimaryHoverBg};
      `,
    },
  });
};

const createTheme: ThemeCreateFunction = () =>
  buildTheme(buildThemeVarsBBC, buildThemeMixinsBBC, buildThemeWidgetStylesBBC);
export default createTheme;
