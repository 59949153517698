import type { Theme } from '@emotion/react';
import { buildTheme } from 'common-ui';
import merge from 'lodash/merge';
import type { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types';
import { buildThemeVarsBBC, buildThemeMixinsBBC, buildThemeWidgetStylesBBC } from 'dw-uk-bbc/theme';

const createTheme: ThemeCreateFunction = () =>
  buildTheme(
    (vars: Pick<Theme, CommonThemeVars>) => merge({}, buildThemeVarsBBC(vars)),
    buildThemeMixinsBBC,
    (vars: Omit<Theme, 'widgets'>) => merge({}, buildThemeWidgetStylesBBC(vars)),
  );

export default createTheme;
